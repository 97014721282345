import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { AnimatedTitle } from 'app/components/Common/Animation/AnimatedTitle'
import { Dots } from 'app/components/Common/Dots'
import { theme } from 'app/theme'
import React, { memo } from 'react'

import { Item, Props as ItemProps } from './Item'

export interface Props {
  items: ItemProps[]
  title?: string
  variant?: Variant
}

export const Cards = memo(function Cards({
  items,
  title,
  variant = 'default',
}: Props) {
  if (items.length < 1) {
    return null
  }

  return (
    <Container variant={variant}>
      {title ? (
        <TitleWrapper>
          <Title variant={variant} text={title} />
          <StyledDots width={8} height={1} variant="cyan" />
        </TitleWrapper>
      ) : null}
      <Wrapper>
        {items.map((item, index) => (
          <Item key={index} {...item} index={index} />
        ))}
      </Wrapper>
    </Container>
  )
})

const Container = styled.section<ContainerProps>`
  position: relative;
  display: block;

  ${({ variant }) => {
    switch (variant) {
      case 'dark':
        return css`
          background-color: ${theme.colors.variants.neutralDark2};
        `
    }
  }}
`

const StyledDots = styled(Dots)`
  @media (max-width: 991px) {
    display: none;
  }
`

const Wrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 10vw;
  margin-bottom: -5vw;

  @media (max-width: 991px) {
    padding: 30px;
    margin-bottom: 0;
    display: block;
  }
`

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10vw 10vw 0;
  margin-bottom: -5vw;

  @media (max-width: 991px) {
    padding: 70px 30px 10px 30px;
  }
`

const Title = styled(AnimatedTitle)<ContainerProps>`
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-weight: bold;
  font-size: 4.16vw;
  line-height: 1.133;

  ${({ variant }) => {
    switch (variant) {
      case 'dark':
        return css`
          color: ${theme.colors.variants.neutralLight1};
        `
    }
  }}

  @media (max-width: 991px) {
    font-size: 28px;
  }
`

interface ContainerProps {
  variant: Variant
}

export type Variant = 'default' | 'dark'
